body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-calendar__tile--now-highlighted {
	background:#01541a !important;
	color: #fff !important;
}

.react-calendar__tile--now-holiday {
	background:orange !important;
	color: #fff !important;
}

.react-calendar__tile--now-absent {
	/* background:red !important; */
	color: black !important;
}

.react-calendar__month-view__days__day--active{
	background-color: white;
	color: #021020 !important;
}